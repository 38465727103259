import { Fragment, lazy } from 'react';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';

import AdminPageLayout from './components/layout/AdminPageLayout';

const NotFoundPage = lazy(() => import('./pages/404'));
import Login from './pages/Login';

const StringsIndex = lazy(() => import('./pages/strings/Index'));

const UsersIndex = lazy(() => import('./pages/users/Index'));
const UserEdit = lazy(() => import('./pages/users/Edit'));

const TagsIndex = lazy(() => import('./pages/tags/Index'));
const TagEdit = lazy(() => import('./pages/tags/Edit'));

import PostsIndex from './pages/posts/Index';
const PostEdit = lazy(() => import('./pages/posts/Edit'));

import NewsIndex from './pages/news/Index';
const NewsEdit = lazy(() => import('./pages/news/Edit'));

const PostCategoriesIndex = lazy(() => import('./pages/posts-categories/Index'));
const PostCategoryEdit = lazy(() => import('./pages/posts-categories/Edit'));

const NewsCategoriesIndex = lazy(() => import('./pages/news-categories/Index'));
const NewsCategoryEdit = lazy(() => import('./pages/news-categories/Edit'));

const Support = lazy(() => import('./pages/Support'));
const Settings = lazy(() => import('./pages/Settings'));

const MenusIndex = lazy(() => import('./pages/menus/Index'));
const MenusEdit = lazy(() => import('./pages/menus/Edit'));

const MenuitemsIndex = lazy(() => import('./pages/menuitems/Index'));
const MenuitemsEdit = lazy(() => import('./pages/menuitems/Edit'));

import PagesIndex from './pages/pages/Index';
const PageEdit = lazy(() => import('./pages/pages/Edit'));

const BannerIndex = lazy(() => import('./pages/banners/Index'));
const BannerEdit = lazy(() => import('./pages/banners/Edit'));

const MosaicIndex = lazy(() => import('./pages/mosaic/Index'));


export default function RouteList({ isauth }) {
  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route path="/login" element={!isauth ? <Login /> : <Navigate to="/" replace />} />
          <Route element={<AdminPageLayout />}>
            <Route path="/" element={isauth ? <Navigate to="/posts" replace /> : <Navigate to="/login" replace />} />

            <Route path="users" element={isauth ? <UsersIndex /> : <Navigate to="/login" replace />} />
            <Route path="users/add" element={isauth ? <UserEdit /> : <Navigate to="/login" replace />} />
            <Route path="user/:userId" element={isauth ? <UserEdit /> : <Navigate to="/login" replace />} />

            <Route path="support" element={isauth ? <Support /> : <Navigate to="/login" replace />} />
            <Route path="settings" element={isauth ? <Settings /> : <Navigate to="/login" replace />} />

            <Route path="tags" element={isauth ? <TagsIndex /> : <Navigate to="/login" replace />} />
            <Route path="tags/add" element={isauth ? <TagEdit /> : <Navigate to="/login" replace />} />
            <Route path="tags/add/:lang_code" element={isauth ? <TagEdit /> : <Navigate to="/login" replace />} />
            <Route path="tags/add/:lang_code/:orig_id" element={isauth ? <TagEdit /> : <Navigate to="/login" replace />} />
            <Route path="tag/:id" element={isauth ? <TagEdit /> : <Navigate to="/login" replace />} />

            <Route path="news" element={isauth ? <NewsIndex /> : <Navigate to="/login" replace />} />
            <Route path="news/add" element={isauth ? <NewsEdit /> : <Navigate to="/login" replace />} />
            <Route path="news/add/:lang_code/:orig_id" element={isauth ? <NewsEdit /> : <Navigate to="/login" replace />} />
            <Route path="news/add/:lang_code" element={isauth ? <NewsEdit /> : <Navigate to="/login" replace />} />
            <Route path="news/:id" element={isauth ? <NewsEdit /> : <Navigate to="/login" replace />} />

            <Route path="posts" element={isauth ? <PostsIndex /> : <Navigate to="/login" replace />} />
            <Route path="posts/add" element={isauth ? <PostEdit /> : <Navigate to="/login" replace />} />
            <Route path="posts/add/:lang_code/:orig_id" element={isauth ? <PostEdit /> : <Navigate to="/login" replace />} />
            <Route path="posts/add/:lang_code" element={isauth ? <PostEdit /> : <Navigate to="/login" replace />} />
            <Route path="post/:id" element={isauth ? <PostEdit /> : <Navigate to="/login" replace />} />

            <Route path="post-categories" element={isauth ? <PostCategoriesIndex /> : <Navigate to="/login" replace />} />
            <Route path="post-categories/add" element={isauth ? <PostCategoryEdit /> : <Navigate to="/login" replace />} />
            <Route path="post-categories/add/:lang_code/:orig_id" element={isauth ? <PostCategoryEdit /> : <Navigate to="/login" replace />} />
            <Route path="post-categories/add/:lang_code" element={isauth ? <PostCategoryEdit /> : <Navigate to="/login" replace />} />
            <Route path="post-category/:id" element={isauth ? <PostCategoryEdit /> : <Navigate to="/login" replace />} />

            <Route path="news-categories" element={isauth ? <NewsCategoriesIndex /> : <Navigate to="/login" replace />} />
            <Route path="news-categories/add" element={isauth ? <NewsCategoryEdit /> : <Navigate to="/login" replace />} />
            <Route path="news-categories/add/:lang_code/:orig_id" element={isauth ? <NewsCategoryEdit /> : <Navigate to="/login" replace />} />
            <Route path="news-categories/add/:lang_code" element={isauth ? <NewsCategoryEdit /> : <Navigate to="/login" replace />} />
            <Route path="news-category/:id" element={isauth ? <NewsCategoryEdit /> : <Navigate to="/login" replace />} />


            <Route path="strings-admin" element={isauth ? <StringsIndex type="strings-admin" /> : <Navigate to="/login" replace />} />
            <Route path="strings-front" element={isauth ? <StringsIndex type="strings-front" /> : <Navigate to="/login" replace />} />

            <Route path="pages" element={isauth ? <PagesIndex /> : <Navigate to="/login" replace />} />
            <Route path="pages/add" element={isauth ? <PageEdit /> : <Navigate to="/login" replace />} />
            <Route path="pages/add/:lang_code/:orig_id" element={isauth ? <PageEdit /> : <Navigate to="/login" replace />} />
            <Route path="pages/add/:lang_code" element={isauth ? <PageEdit /> : <Navigate to="/login" replace />} />
            <Route path="page/:id" element={isauth ? <PageEdit /> : <Navigate to="/login" replace />} />


            {/* <Route path="rss" element={isauth ? <RSSIndex /> : <Navigate to="/login" replace />} />
              <Route path="rss/add" element={isauth ? <RSSEdit /> : <Navigate to="/login" replace />} />
              <Route path="rss/:id" element={isauth ? <RSSEdit /> : <Navigate to="/login" replace />} /> */}

            <Route path="frontend/banners" element={isauth ? <BannerIndex /> : <Navigate to="/login" replace />} />
            <Route path="frontend/banner/add" element={isauth ? <BannerEdit /> : <Navigate to="/login" replace />} />
            <Route path="frontend/banner/add/:lang_code" element={isauth ? <BannerEdit /> : <Navigate to="/login" replace />} />
            <Route path="frontend/banner/:id" element={isauth ? <BannerEdit /> : <Navigate to="/login" replace />} />

            <Route path="frontend/menus" element={isauth ? <MenusIndex /> : <Navigate to="/login" replace />} />
            <Route path="frontend/menu/add" element={isauth ? <MenusEdit /> : <Navigate to="/login" replace />} />
            <Route path="frontend/menu/add/:lang_code" element={isauth ? <MenusEdit /> : <Navigate to="/login" replace />} />
            <Route path="frontend/menu/add/:lang_code/:orig_id" element={isauth ? <MenusEdit /> : <Navigate to="/login" replace />} />
            <Route path="frontend/menu/:id" element={isauth ? <MenusEdit /> : <Navigate to="/login" replace />} />

            <Route path="frontend/menuitems/:menu_id" element={isauth ? <MenuitemsIndex /> : <Navigate to="/login" replace />} />
            <Route path="frontend/menuitem/add/:menu_id" element={isauth ? <MenuitemsEdit /> : <Navigate to="/login" replace />} />
            <Route path="frontend/menuitem/:id" element={isauth ? <MenuitemsEdit /> : <Navigate to="/login" replace />} />

            <Route path="frontend/lifestyle-mosaic" element={isauth ? <MosaicIndex /> : <Navigate to="/login" replace />} />


            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Route>
      </Routes>
    </Fragment>
  );
}