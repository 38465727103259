/**
 * Translate description: Sidebar admin-menu
 */

import { useState, Fragment, useEffect } from 'react';
import { useLocation, Link as ReactLink, useNavigate } from "react-router-dom";
import { useLanguageContext } from "../../context/LanguageContext";

import Box from '@mui/joy/Box';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined';
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined';
import AutoStoriesRoundedIcon from '@mui/icons-material/AutoStoriesRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import TranslateIcon from '@mui/icons-material/Translate';
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';
import BackpackRoundedIcon from '@mui/icons-material/BackpackRounded';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const linkStyle = { textDecoration: 'none', display: 'block', width: '100%' };

function locateIn(location, regexarr) {
  return !!regexarr.filter(v => {
    const regexp = new RegExp(`^${v}$`);
    return regexp.test(location.pathname);
  }).length;
}

function Toggler({ defaultExpanded = false, locationWhereOpened = [], renderToggle, children }) {
  const [open, setOpen] = useState(defaultExpanded);
  useEffect(() => {
    setOpen(locateIn(location, locationWhereOpened));
  }, [location.pathname]);

  return (
    <Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: open ? '1fr' : '0fr',
          transition: '0.2s ease',
          '& > *': {
            overflow: 'hidden',
          },
        }}
      >
        {children}
      </Box>
    </Fragment>
  );
}

function MenuItem({ children, to, selected, icon }) {
  const location = useLocation();
  return (
    <ListItem>
      <ReactLink to={to} style={linkStyle}>
        <ListItemButton selected={locateIn(location, selected)}>
          {icon}
          <ListItemContent>
            <Typography level="title-sm">{children}</Typography>
          </ListItemContent>
        </ListItemButton>
      </ReactLink>
    </ListItem>
  );
}

function SubMenu({ children, title, selected, icon, href }) {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <ListItem nested>
      <Toggler
        locationWhereOpened={selected}
        renderToggle={({ open, setOpen }) => (
          <ListItemButton onClick={() => {
            (href && location.pathname !== href) ? navigate(href) : setOpen(!open);
          }}>
            {icon}
            <ListItemContent>
              <Typography level="title-sm">{title}</Typography>
            </ListItemContent>
            <KeyboardArrowDownIcon sx={{ transform: open ? 'rotate(180deg)' : 'none' }} />
          </ListItemButton>
        )}
      >
        <List sx={{ gap: 0.5 }}>{children}</List>
      </Toggler>
    </ListItem>
  );
}

function SubItem({ children, to, selected }) {
  const location = useLocation();
  return (
    <ListItem>
      <ReactLink to={to} style={linkStyle}>
        <ListItemButton selected={locateIn(location, selected)}>{children}</ListItemButton>
      </ReactLink>
    </ListItem>
  )
}


export default function SidebarMenu() {
  const { t } = useLanguageContext();
  return (
    <>
      <List size="sm" sx={{ gap: 1, '--List-nestedInsetStart': '30px', '--ListItem-radius': (theme) => theme.vars.radius.sm }}>
        <SubMenu
          title={t("News")}
          selected={['/news', '/news/.*', '/news-categories', '/news-category/\\d+', '/news-categories/.*']}
          icon={<FeedOutlinedIcon />}
          href='/news'
        >
          <SubItem to="/news" selected={['/news', '/news/.*']}>
            {t("All news")}
          </SubItem>
          <SubItem to="/news-categories" selected={['/news-categories', '/news-category/\\d+', '/news-categories/.*']}>
            {t("Categories")}
          </SubItem>
        </SubMenu>


        <SubMenu
          title={t("Posts")}
          selected={['/posts', '/posts/.*', '/post-categories', '/post/\\d+', '/post-category/\\d+', '/post-categories/.*']}
          icon={<NewspaperOutlinedIcon />}
          href='/posts'
        >
          <SubItem to="/posts" selected={['/posts', '/posts/.*', '/post/\\d+']}>
            {t("All posts")}
          </SubItem>
          <SubItem to="/post-categories" selected={['/post-categories', '/post-category/\\d+', '/post-categories/.*']}>
            {t("Categories")}
          </SubItem>
        </SubMenu>


        <MenuItem to={"/pages"} selected={['/pages', '/page/\\d+', '/pages/add/.*']} icon={<AutoStoriesRoundedIcon />}>{t("Pages")}</MenuItem>

        <MenuItem to={"/tags"} selected={['/tags', '/tag/\\d+', '/tags/add/.*']} icon={<StyleOutlinedIcon />}>{t("Tags")}</MenuItem>


        <SubMenu
          title={t("Strings l10n")}
          selected={['/strings-admin', '/strings-front']}
          icon={<TranslateIcon />}
          href='/strings-front'
        >
          <SubItem to="/strings-front" selected={['/strings-front']}>{t("Front strings")}</SubItem>
          <SubItem to="/strings-admin" selected={['/strings-admin']}>{t("Admin strings")}</SubItem>
        </SubMenu>


        <SubMenu
          title={t("Frontend")}
          selected={[
            '/frontend/menus', '/frontend/menu/.*', '/frontend/menus/.*', '/frontend/menu/\\d+', '/frontend/menuitems?/\\d+',
            '/frontend/banners', '/frontend/banners/.*', '/frontend/banner/\\d+',
            '/frontend/lifestyle-mosaic'
          ]}
          icon={<ViewQuiltRoundedIcon />}
          href='/frontend/menus'
        >
          <SubItem to="/frontend/menus" selected={['/frontend/menus', '/frontend/menu/.*', '/frontend/menus/.*', '/frontend/menu/\\d+', '/frontend/menuitems?/\\d+']}>{t("Menus")}</SubItem>
          <SubItem to="/frontend/banners" selected={['/frontend/banners', '/frontend/banners/.*', '/frontend/banner/\\d+']}>{t("Banners")}</SubItem>
          <SubItem to="/frontend/lifestyle-mosaic" selected={['/frontend/lifestyle-mosaic']}>{t("Mosaic LifeStyle")}</SubItem>
        </SubMenu>
      </List>


      <List size="sm" sx={{ mt: 'auto', flexGrow: 0, '--ListItem-radius': (theme) => theme.vars.radius.sm, '--List-gap': '4px', mb: 2, '--List-nestedInsetStart': '30px', }}>
        <MenuItem to={"/users"} selected={['/users', '/user/.*', '/users/.*']} icon={<PeopleAltRoundedIcon />}>{t("Users")}</MenuItem>
        <MenuItem to={"/settings"} selected={['settings']} icon={<SettingsRoundedIcon />}>{t("Settings")}</MenuItem>
      </List>
    </>
  )
}